import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import MediaBlock from "../components/organisms/media-block";
import MediaCarousel from "../components/organisms/media-carousel";
// import CodeBlock from "../components/atoms/code-block";
const CodeBlock = loadable(() => import("../components/atoms/code-block"));

function DesignReviews({ props }) {
  const designProps = props;
  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Reviews" />
      <h2>Reviews</h2>
      <div className="design__stories">
        <div className="design__story-section">
          <DesignStory
            title="Reviews"
            component={MediaCarousel}
            subComponent={MediaBlock}
            type="carousel"
            props={designProps}
          />

          <p className="design__text p2">
            This example assumes that you&apos;ve set values to manage the
            review modal state:
          </p>
          <CodeBlock language="jsx">
            {`const [showReviewModal, setShowReviewModal] = useState();
const [reviewModalContent, setReviewModalContent] = useState();`}
          </CodeBlock>

          <p className="design__text p2">
            And you have passed these values to the reviewModal prop of the
            MediaCarousel component:
          </p>
          <CodeBlock language="jsx">
            {`reviewModal={{
  showReviewModal,
  reviewModalContent,
  setShowReviewModal,
}}`}
          </CodeBlock>

          <p className="design__text p2">
            And to the reviewModal prop inside of a props prop of each
            MediaBlock:
          </p>
          <CodeBlock language="jsx">
            {`props={{         
  reviewModal={{
    setShowReviewModal,
    setReviewModalContent,
  }}
}}`}
          </CodeBlock>
        </div>
      </div>
    </div>
  );
}

DesignReviews.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
};

DesignReviews.defaultProps = {
  props: {},
};

export default DesignReviews;
